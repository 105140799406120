<template>
  <form
    @submit.prevent="onSubmit"
    class="login"
  >
    <div class="row mb-2">
      <va-input
        v-model="form.email.value"
        type="email"
        :label="$t('auth.email_recovery')"
        :error="!!form.email.errors.length"
        :error-messages="translatedErrors(form.email.errors)"
      />
    </div>
    <div class="row justify--center">
      <va-button color="primary">
        <text-loading :loading="loading">{{ $t('auth.reset_password') }}</text-loading>
      </va-button>
    </div>
  </form>
</template>

<script>
import { isFormReady, hasFormErrors } from '@/services/validator'

export default {
  name: 'recover-password',
  data () {
    return {
      loading: false,
      form: {
        email: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
      },
    }
  },
  computed: {
    formReady () {
      return isFormReady(this.form)
    },
  },
  methods: {
    translatedErrors (errors) {
      const e = []
      for (const error of errors) {
        e.push(this.$t(error.label, error.context))
      }
      return e
    },
    async onSubmit () {
      hasFormErrors(this.form)
      if (!this.formReady) {
        return
      }

      this.loading = true
      const emailForm = this.form.email.value
      try {
        await this.$http.post('auth/forgot-password', { email: emailForm })
      } catch (e) {
        console.log('Error recovery', e)
        let message = 'auth.recovery.error'
        if (e.request.status === 404) {
          message = 'auth.recovery.missing'
        }
        this.showToast(this.$t(message), { icon: 'fa-times' })
        this.loading = false
        return
      }

      this.showToast(this.$t('auth.recovery.success'), { icon: 'fa-check' })
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
</style>
